// src/components/AdminSection.jsx
import React from 'react';
import { Building2, Activity, Users, UserCog } from 'lucide-react';
import { motion } from 'framer-motion';
import { StatCard } from './StatCard';

export const AdminSection = () => (
  <div className="space-y-12">
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      <StatCard icon={Building2} title="Eficiencia Operativa" value="94%" change={7} />
      <StatCard icon={Activity} title="Presupuesto Utilizado" value="82%" change={-5} />
      <StatCard icon={Users} title="Personal Activo" value="156" change={2} />
    </div>
    
    {/* Rest of the AdminSection component remains the same */}
    {/* You can copy the rest from your original AdminSection component */}
  </div>
);