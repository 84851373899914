import React, { useState } from 'react';
import { 
  Bell, Activity, Map, Shield, Users, Phone, 
  Eye, Lock, Trophy, LineChart, UserCircle, 
  Building2, AlertTriangle, MessageCircle, 
  Star, Camera, Radio, Calendar, Clock,
  Trash2, Lightbulb, Droplet
} from 'lucide-react';
import { motion } from 'framer-motion';

const FeatureList = ({ features }) => (
  <div className="space-y-2">
    {features.map((feature, index) => (
      <motion.div
        key={index}
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: index * 0.1 }}
        className="flex items-center gap-2 text-gray-300"
      >
        <div className="h-1.5 w-1.5 rounded-full bg-rose-500" />
        <span className="text-sm">{feature}</span>
      </motion.div>
    ))}
  </div>
);

const StatBadge = ({ icon: Icon, label, value }) => (
  <div className="flex items-center gap-2 bg-gray-800/50 rounded-lg p-2">
    <Icon className="h-4 w-4 text-rose-400" />
    <div className="flex flex-col">
      <span className="text-xs text-gray-400">{label}</span>
      <span className="text-sm font-medium text-white">{value}</span>
    </div>
  </div>
);

const DashboardCard = ({ 
  title, 
  description, 
  bgImage, 
  icon: Icon, 
  features, 
  stats,
  primaryAction 
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <motion.div 
      className="relative h-full overflow-hidden rounded-2xl flex flex-col bg-gray-900"
      whileHover={{ scale: 1.02 }}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
    >
      {/* Image Section */}
      <div 
        className="relative h-[40%] w-full overflow-hidden"
        style={{
          backgroundImage: bgImage ? `url(${bgImage})` : undefined,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <motion.div 
          className="absolute inset-0 bg-gradient-to-b from-gray-900/30 to-gray-900/80"
          animate={{ opacity: isHovered ? 0.9 : 0.7 }}
        />
        <div className="absolute top-4 left-4 flex items-center gap-3">
          <motion.div 
            className="p-3 bg-rose-500/10 backdrop-blur-md rounded-lg"
            whileHover={{ scale: 1.1 }}
          >
            <Icon className="h-6 w-6 text-rose-500" />
          </motion.div>
          {primaryAction && (
            <motion.button
              className="px-4 py-2 bg-rose-500 text-white rounded-lg text-sm font-medium"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {primaryAction}
            </motion.button>
          )}
        </div>
      </div>
      
      {/* Content Section */}
      <div className="flex-1 p-6 space-y-6">
        <div>
          <h3 className="text-xl font-bold text-white mb-3">{title}</h3>
          <p className="text-gray-300 text-sm leading-relaxed">{description}</p>
        </div>

        {/* Stats Grid */}
        {stats && (
          <div className="grid grid-cols-2 gap-2">
            {stats.map((stat, index) => (
              <StatBadge key={index} {...stat} />
            ))}
          </div>
        )}

        {/* Features List */}
        {features && <FeatureList features={features} />}
      </div>
    </motion.div>
  );
};

const BentoGrid = () => (
  <div className="max-w-7xl mx-auto p-6 px-4">
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 auto-rows-fr">
      {/* Portal Ciudadano - Tall Card */}
      <div className="md:col-span-2 md:row-span-2">
        <DashboardCard
          title="Portal Ciudadano"
          description="Plataforma integral de seguridad ciudadana con funciones avanzadas de reporte y comunicación."
          icon={Users}
          bgImage="/dashboardPolice.png"
          primaryAction="Acceder"
          stats={[
            { icon: Bell, label: "Tiempo de Respuesta", value: "< 3 min" },
            { icon: Map, label: "Cobertura", value: "100%" },
            { icon: MessageCircle, label: "Chat Activo", value: "24/7" },
            { icon: Star, label: "Satisfacción", value: "4.8/5" }
          ]}
          features={[
            "Botón de pánico con geolocalización en tiempo real",
            "Chat directo con servicios de emergencia",
            "Envío de fotos, audio y video",
            "Sistema de alertas vecinales",
            "Seguimiento de servicios municipales",
            "Calificación del servicio recibido"
          ]}
        />
      </div>

      {/* Dashboard Agentes */}
      <div>
        <DashboardCard
          title="Dashboard Agentes"
          description="Sistema táctico para respuesta inmediata y coordinación efectiva en campo."
          icon={Shield}
          bgImage="/dashboardPolice.png"
          stats={[
            { icon: Clock, label: "Tiempo Promedio", value: "2.5 min" },
            { icon: Trophy, label: "Efectividad", value: "95%" }
          ]}
          features={[
            "Gestión de emergencias en tiempo real",
            "Comunicación interna segura",
            "Registro de incidentes",
            "Seguimiento GPS de unidades"
          ]}
        />
      </div>

      {/* Centro de Mando */}
      <div>
        <DashboardCard
          title="Centro de Mando"
          description="Central de monitoreo y análisis con tecnología de vanguardia."
          icon={LineChart}
          bgImage="/dashboardCentro.png"
          stats={[
            { icon: Activity, label: "Monitoreo", value: "24/7" },
            { icon: Eye, label: "Cobertura", value: "100%" }
          ]}
          features={[
            "Análisis en tiempo real",
            "Mapas de calor de incidencias",
            "Gestión de recursos policiales",
            "Reportes estadísticos avanzados"
          ]}
        />
      </div>
    </div>
  </div>
);

export default BentoGrid;