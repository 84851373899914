import React from 'react';
import { Shield, Users, Bell } from 'lucide-react';

const FeatureCard = ({ icon: Icon, title, description, subFeatures }) => {
  return (
    <div className="bg-gray-900 rounded-xl border border-gray-800 h-full p-6">
      <div className="flex items-center mb-4">
        <div className="w-12 h-12 bg-rose-500/10 rounded-lg flex items-center justify-center flex-shrink-0">
          <Icon className="w-6 h-6 text-rose-500" />
        </div>
        <h3 className="text-lg font-semibold text-white ml-4">{title}</h3>
      </div>
      <p className="text-sm text-gray-400 mb-4">{description}</p>
      <ul className="space-y-3">
        {subFeatures.map((feature, index) => (
          <li key={index} className="flex items-start gap-2">
            <div className="w-1.5 h-1.5 rounded-full bg-rose-500 mt-2 flex-shrink-0" />
            <span className="text-sm text-gray-300">{feature}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

const DashboardShowcase = () => {
  const features = [
    {
      icon: Shield,
      title: "Seguridad Proactiva",
      description: "Sistema integral de alertas y respuesta inmediata para la protección ciudadana",
      subFeatures: [
        "Reportes en tiempo real con respuesta inmediata",
        "Botón de pánico con conexión directa a emergencias",
        "Mapeo inteligente de zonas de riesgo"
      ]
    },
    {
      icon: Users,
      title: "Comunidad Conectada",
      description: "Red colaborativa que fortalece la seguridad mediante la participación ciudadana",
      subFeatures: [
        "Red de vigilancia comunitaria activa",
        "Sistema de verificación cruzada de reportes",
        "Comunicación directa entre vecinos y autoridades"
      ]
    },
    {
      icon: Bell,
      title: "Monitoreo Inteligente",
      description: "Análisis y seguimiento detallado del impacto en la seguridad comunitaria",
      subFeatures: [
        "Estadísticas en tiempo real de incidentes",
        "Métricas de efectividad de respuesta",
        "Visualización de patrones de seguridad"
      ]
    }
  ];

  return (
    <div className="relative bg-gray-950 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        {/* Header */}
        <div className="text-center mb-12">
          <h1 className="text-3xl lg:text-4xl font-bold text-white mb-3">
            Dashboard Ciudadano
          </h1>
          <p className="text-lg text-gray-400 max-w-2xl mx-auto">
            Monitoreo de Seguridad en Tiempo Real
          </p>
        </div>

        {/* Main Content */}
        <div className="grid lg:grid-cols-2  items-center">
          {/* Left side - Images */}
          <div className="relative">
            {/* Main Phone */}
            <div className="relative z-20">
              <img 
                src="/dashboarUser.png" 
                alt="Dashboard Principal"
                className="w-full max-w-sm mx-auto rounded-2xl shadow-2xl"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-gray-900/20 to-transparent " />
            </div>

            {/* Secondary Phone */}

            {/* Glow Effect */}
            <div className="absolute -inset-4 bg-rose-500/10 rounded-3xl blur-2xl -z-10" />
          </div>

          {/* Right side - Feature Grid */}
          <div className="grid gap-6 -mt-10 z-30">
            {features.map((feature, index) => (
              <FeatureCard 
                key={index}
                {...feature}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardShowcase;