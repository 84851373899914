import React, { useEffect, useRef } from 'react';
import { Award, TrendingUp, Users, Shield, ChevronRight } from 'lucide-react';

const useScrollReveal = () => {
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('opacity-100', 'translate-y-0');
          entry.target.classList.remove('opacity-0', 'translate-y-8');
        }
      },
      {
        threshold: 0.1,
        rootMargin: '-50px',
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return ref;
};

const FlexibleImageCard = ({ imageSrc, imageAlt }) => {
  return (
    <div className="bg-gray-900 rounded-xl border border-gray-800 h-full">
      <div className="relative h-full">
        <img
          src={imageSrc}
          alt={imageAlt}
          className="w-full h-[190px] object-cover rounded-xl"
        />
      </div>
    </div>
  );
};

const NewsCard = ({ date, title, excerpt, image, icon: Icon }) => {
  const cardRef = useScrollReveal();

  return (
    <div
      ref={cardRef}
      className="bg-gray-800/50 backdrop-blur-md border border-gray-700 rounded-xl overflow-hidden opacity-0 translate-y-8 transition-all duration-700 ease-out"
    >
      
      <div className="relative h-68 w-full">
        <img 
          src={image} 
          alt={title}
          className="w-full h-full object-cover"
        />
        <div className="absolute top-4 left-4 bg-rose-500 p-2 rounded-lg">
          <Icon className="h-5 w-5 text-white" />
        </div>
      </div>
      <div className="p-6">
        <span className="text-rose-400 text-sm font-medium">{date}</span>
        <h3 className="text-xl font-bold mt-2 mb-3">{title}</h3>
        <p className="text-gray-400 text-sm mb-4">{excerpt}</p>
        
      </div>
      
    </div>
    
  );
};

const newsItems = [
  {
    date: '26 de Octubre, 2024',
    title: 'Amaxac revoluciona la seguridad ciudadana con innovadora app',
    excerpt: 'El municipio da un paso histórico en materia de seguridad al presentar una aplicación que conecta directamente a ciudadanos con servicios de emergencia.',
    image: '/SLIDER1.png',
    icon: Shield
  },
  {
    date: '27 de Octubre, 2024',
    title: '500 descargas en el primer día de AmaxacSeguro',
    excerpt: 'La respuesta ciudadana supera las expectativas con una adopción masiva de la nueva plataforma de seguridad municipal.',
    image: '/imagenSecurity.png',
    icon: TrendingUp
  }
];

const PressReleases = () => (
  <div className="py-8 px-4 md:px-6">
    <div className="max-w-7xl mx-auto">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {newsItems.map((item, index) => (
          <NewsCard 
            key={index}
            {...item}
          />
        ))}
      </div>
    </div>
    <div className="pt-4">
    <FlexibleImageCard imageSrc="/notaSolTlaxcala.png" imageAlt="Police" />
    </div>
    
  </div>
);

export default PressReleases;