import React, { useState, useRef, useEffect } from 'react';
import { AlertTriangle, Camera, Mic, MapPin, X, Camera as CameraFront, Shield, Signal, Battery, Wifi } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const AlertCard = ({ icon: Icon, title, description, pulseColor = "rose" }) => (
  <div className={`bg-${pulseColor}-950/80 border border-${pulseColor}-500 rounded-lg p-4 relative overflow-hidden`}>
    <motion.div 
      className="absolute inset-0 bg-gradient-to-r from-transparent via-white/10 to-transparent"
      animate={{ x: ['-100%', '100%'] }}
      transition={{ repeat: Infinity, duration: 2 }}
    />
    <div className="flex items-start space-x-3 relative z-10">
      <Icon className={`w-5 h-5 text-${pulseColor}-500 mt-1`} />
      <div>
        <h3 className={`font-semibold text-${pulseColor}-100`}>{title}</h3>
        <p className={`text-${pulseColor}-200/80 text-sm mt-1`}>{description}</p>
      </div>
    </div>
  </div>
);

const EmergencyButton = () => {
  const [isPressing, setIsPressing] = useState(false);
  const [panicMode, setPanicMode] = useState(false);
  const [pressProgress, setPressProgress] = useState(0);
  const [location, setLocation] = useState({ lat: 0, lng: 0 });
  const [batteryLevel, setBatteryLevel] = useState(100);
  const [signalStrength, setSignalStrength] = useState(4);
  const pressTimer = useRef(null);
  const progressInterval = useRef(null);

  useEffect(() => {
    return () => {
      if (pressTimer.current) clearInterval(pressTimer.current);
      if (progressInterval.current) clearInterval(progressInterval.current);
    };
  }, []);

  useEffect(() => {
    if (panicMode) {
      const interval = setInterval(() => {
        setLocation({
          lat: 19.432608 + (Math.random() - 0.5) * 0.001,
          lng: -99.133209 + (Math.random() - 0.5) * 0.001
        });
        setBatteryLevel(prev => Math.max(prev - 0.5, 0));
        setSignalStrength(Math.floor(Math.random() * 5));
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [panicMode]);

  const handlePressStart = (e) => {
    e.preventDefault();
    setIsPressing(true);
    setPressProgress(0);
    
    progressInterval.current = setInterval(() => {
      setPressProgress(prev => {
        const newProgress = prev + 2;
        if (newProgress >= 100) {
          clearInterval(progressInterval.current);
          setPanicMode(true);
          setIsPressing(false);
          return 100;
        }
        return newProgress;
      });
    }, 60);
  };

  const handlePressEnd = () => {
    if (progressInterval.current) {
      clearInterval(progressInterval.current);
    }
    setIsPressing(false);
    setPressProgress(0);
  };

  const deactivatePanic = () => {
    setPanicMode(false);
    setLocation({ lat: 0, lng: 0 });
    setBatteryLevel(100);
  };

  if (panicMode) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="fixed inset-0 bg-black/95 text-white p-4 z-[9999]"
      >
        <div className="max-w-3xl mx-auto">
          <div className="flex justify-between items-center mb-6">
            <div>
              <h2 className="text-2xl font-bold text-rose-500">MODO PÁNICO ACTIVADO</h2>
              <div className="flex items-center space-x-4 mt-2">
                <div className="flex items-center">
                  <Battery className="w-4 h-4 text-rose-500 mr-1" />
                  <span className="text-sm text-rose-300">{batteryLevel.toFixed(1)}%</span>
                </div>
                <div className="flex items-center">
                  <Signal className="w-4 h-4 text-rose-500 mr-1" />
                  <span className="text-sm text-rose-300">{signalStrength}/4</span>
                </div>
                <div className="flex items-center">
                  <Wifi className="w-4 h-4 text-rose-500 mr-1" />
                  <span className="text-sm text-rose-300">Conectado</span>
                </div>
              </div>
            </div>
            <motion.button 
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={deactivatePanic}
              className="bg-rose-600 hover:bg-rose-700 text-white px-4 py-2 rounded-lg flex items-center space-x-2"
            >
              <X className="w-5 h-5" />
              <span>Desactivar</span>
            </motion.button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
            {[
              { icon: MapPin, title: "Compartiendo Ubicación", description: `Lat: ${location.lat.toFixed(6)}, Lng: ${location.lng.toFixed(6)}` },
              { icon: Camera, title: "Cámara Trasera Activa", description: `Grabando video... ${Math.floor(Math.random() * 100)}MB` },
              { icon: CameraFront, title: "Cámara Frontal Activa", description: `Grabando video... ${Math.floor(Math.random() * 100)}MB` },
              { icon: Mic, title: "Micrófono Activo", description: `Grabando audio... ${Math.floor(Math.random() * 100)}MB` }
            ].map((alert, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <AlertCard {...alert} />
              </motion.div>
            ))}
          </div>

          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: [0, 1, 0] }}
            transition={{ repeat: Infinity, duration: 2 }}
            className="text-center text-rose-500 text-sm"
          >
            Transmitiendo datos en tiempo real al centro de control...
          </motion.div>
        </div>
      </motion.div>
    );
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 p-4 bg-gray-900/95 backdrop-blur-sm border-t border-gray-800 z-[9999]">
      <div className="max-w-md mx-auto relative">
        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onMouseDown={handlePressStart}
          onMouseUp={handlePressEnd}
          onMouseLeave={handlePressEnd}
          onTouchStart={handlePressStart}
          onTouchEnd={handlePressEnd}
          className="w-full bg-gradient-to-r from-red-600 to-rose-500 text-white px-6 py-4 rounded-full 
                    font-bold text-lg shadow-lg hover:shadow-xl transition-all duration-300
                    flex items-center justify-center space-x-3 relative overflow-hidden"
          style={{ userSelect: 'none', touchAction: 'none' }}
        >
          {isPressing && (
            <motion.div
              className="absolute inset-0 bg-white/20"
              initial={{ width: '0%' }}
              animate={{ width: `${pressProgress}%` }}
              transition={{ duration: 0.1 }}
            />
          )}
          <Shield className="w-6 h-6" />
          <span>BOTÓN DE EMERGENCIA</span>
        </motion.button>
        <AnimatePresence>
          {isPressing && (
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              className="absolute -top-8 left-0 right-0 text-center text-sm text-rose-400 font-semibold"
            >
              Mantenga presionado... {pressProgress.toFixed(0)}%
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default EmergencyButton;