import React from 'react';
import { motion } from 'framer-motion';

export const StatCard = ({ icon: Icon, title, value, change }) => (
  <motion.div
    whileHover={{ y: -5 }}
    className="bg-gray-800/50 backdrop-blur-md p-6 rounded-xl border border-gray-700"
  >
    <div className="flex items-start justify-between">
      <div>
        <p className="text-gray-400 mb-1">{title}</p>
        <h3 className="text-3xl font-bold text-white">{value}</h3>
      </div>
      <Icon className="w-8 h-8 text-rose-500" />
    </div>
    <p className={`mt-2 ${change >= 0 ? 'text-green-400' : 'text-rose-400'}`}>
      {change >= 0 ? '↑' : '↓'} {Math.abs(change)}% vs mes anterior
    </p>
  </motion.div>
);
