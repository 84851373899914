import React from 'react';

const ImageCardNoText = ({ imageSrc, imageAlt }) => {
  return (
    <div className="relative  overflow-hidden">
      <div className="h-69 w-full overflow-hidden">
        <img
          src={imageSrc}
          alt={imageAlt}
          className="w-full h-full object-cover object-center transform hover:scale-110 transition duration-500"
        />
      </div>
    </div>
  );
};

export default ImageCardNoText;