// src/App.jsx
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Layout } from './components/Layout';

import { CitizenSection } from './components/CitizenSection';
import { PoliceSection } from './components/PoliceSection';
import { AdminSection } from './components/AdminSection';
import BentoGrid from './components/BentoGrid';
import Hero from './components/Hero';
import ImageCardNoText from './components/ImageCardNoText';
import DashboardShowcase from './components/DashboardShowcase';

import PressReleases from './components/PressReleases';
import PoliceDashboard from './components/PoliceDashboard';
import ImprovedDashboard from './components/ImprovedDashboard';



const App = () => {
  const [activeTab, setActiveTab] = useState('citizen');

  return (
    <Layout activeTab={activeTab} setActiveTab={setActiveTab}>
      <Hero />
      <BentoGrid />
      <div className="pl-2 -mt-4">
      <ImageCardNoText
        imageSrc="/userApp.png"
        imageAlt="cardUserApp"
      />
      </div>
      
      
      <ImprovedDashboard />
    
      <DashboardShowcase />
      <PoliceDashboard />
      
      
      
      <PressReleases />
      
      


      
      <div>
        <AnimatePresence mode="wait">
          <motion.div
            key={activeTab}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            {activeTab === 'citizen' && <CitizenSection />}
            {activeTab === 'police' && <PoliceSection />}
            {activeTab === 'admin' && <AdminSection />}
          </motion.div>
        </AnimatePresence>
      </div>
    </Layout>
  );
};

export default App;

