import React from 'react';

const PoliceImageCard = () => {
  return (
    <div className="bg-gray-900 rounded-xl border border-gray-800 mt-6 h-full">
      <div className="relative h-full">
        <img
          src="/police.jpeg"
          alt="Police"
          className="w-full h-full object-cover rounded-xl"
        />
      </div>
    </div>
  );
};

export default PoliceImageCard;