import React from 'react';
import { Bell, Activity, Map, Shield, Users, Phone, Eye, Lock, Trophy } from 'lucide-react';


const mockData = {
  incidents: [
    { month: 'Ene', count: 65 },
    { month: 'Feb', count: 59 },
    { month: 'Mar', count: 80 },
    { month: 'Abr', count: 45 },
    { month: 'May', count: 73 },
    { month: 'Jun', count: 48 }
  ]
};

const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="bg-gray-800/50 backdrop-blur-md border border-gray-700 rounded-xl p-6">
    <div className="flex items-start space-x-4">
      <div className="p-2 bg-rose-500/10 rounded-lg">
        <Icon className="h-6 w-6 text-rose-500" />
      </div>
      <div>
        <h4 className="text-lg font-semibold mb-2">{title}</h4>
        <p className="text-gray-400 text-sm">{description}</p>
      </div>
    </div>
  </div>
);


export const CitizenSection = () => (
  <div >
  
    
    
  
    
    
    
  </div>
);