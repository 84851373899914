// src/components/PoliceSection.jsx
import React from 'react';
import { Users, Activity, Shield } from 'lucide-react';
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { StatCard } from './StatCard';

const mockData = {
  response: [
    { month: 'Ene', time: 8.2 },
    { month: 'Feb', time: 7.8 },
    { month: 'Mar', time: 6.9 },
    { month: 'Abr', time: 6.2 },
    { month: 'May', time: 5.8 },
    { month: 'Jun', time: 5.5 }
  ]
};

export const PoliceSection = () => (
  <div className="space-y-12">
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      <StatCard icon={Users} title="Oficiales Activos" value="45" change={5} />
      <StatCard icon={Activity} title="Patrullas en Ruta" value="12" change={0} />
      <StatCard icon={Shield} title="Casos Resueltos" value="92%" change={3} />
    </div>
    
    <div className="bg-gray-800/50 backdrop-blur-md p-6 rounded-xl border border-gray-700">
      <h3 className="text-xl font-bold mb-4">Tiempo de Respuesta Promedio (minutos)</h3>
      <div className="h-[300px]">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={mockData.response}>
            <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
            <XAxis dataKey="month" stroke="#9ca3af" />
            <YAxis stroke="#9ca3af" />
            <Tooltip 
              contentStyle={{ 
                backgroundColor: '#1f2937',
                border: '1px solid #374151',
                borderRadius: '0.5rem'
              }}
            />
            <Line 
              type="monotone" 
              dataKey="time" 
              stroke="#8b5cf6" 
              strokeWidth={2}
              dot={{ fill: '#8b5cf6', strokeWidth: 2 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  </div>
);
