import React from 'react';
import { Users, Shield, Building2 } from 'lucide-react';

const ShieldLogo = () => {
  const [currentIcon, setCurrentIcon] = React.useState(0);
  const icons = [
    { Icon: Users, label: "Ciudadanos" },
    { Icon: Shield, label: "Policía" },
    { Icon: Building2, label: "Administración" }
  ];

  React.useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIcon((prev) => (prev + 1) % icons.length);
    }, 2000);
    return () => clearInterval(timer);
  }, []);

  const IconComponent = icons[currentIcon].Icon;

  return (
    <svg 
      viewBox="0 0 100 120" 
      className="w-24 h-24 sm:w-32 sm:h-32 mb-6"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path 
        d="M50 10 L90 30 L90 70 C90 85 70 105 50 110 C30 105 10 85 10 70 L10 30 Z" 
        className="text-purple-500"
        fill="currentColor"
        opacity="0.2"
      />
      <path 
        d="M50 10 L90 30 L90 70 C90 85 70 105 50 110 C30 105 10 85 10 70 L10 30 Z" 
        className="text-rose-500"
        strokeDasharray="300"
        strokeDashoffset="300"
        style={{
          animation: "drawShield 2s ease-out forwards"
        }}
      />
      <foreignObject x="30" y="40" width="40" height="40">
        <div className="w-full h-full flex items-center justify-center">
          <IconComponent 
            className="w-8 h-8 text-white animate-iconTransition"
            strokeWidth={1.5}
          />
        </div>
      </foreignObject>
    </svg>
  );
};

const ImageCard = () => (
  <div className="w-full overflow-hidden flex-shrink-0">
    <div className="relative w-full aspect-[5.05/10]">
      <img 
        src="/movilHero1.png" 
        alt="Security camera footage" 
        className="w-full h-full object-cover"
      />
      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-gray-900/50" />
    </div>
  </div>
);

const ScrollColumn = ({ direction = 'up' }) => {
  // Calculamos cuántas imágenes necesitamos para cubrir la pantalla y un poco más
  const images = [...Array(4)].map((_, i) => (
    <ImageCard key={i} />
  ));

  return (
    <div className="relative flex flex-col gap-2 h-screen overflow-hidden">
      <div 
        className={`flex flex-col gap-2`}
        style={{
          animation: `scroll${direction === 'up' ? 'Up' : 'Down'} 25s linear infinite`
        }}
      >
        {images}
        {images} {/* Duplicamos para crear el efecto continuo */}
      </div>
    </div>
  );
};

const Hero = () => (
  <div className="relative w-full h-screen bg-gray-900 overflow-hidden">
    {/* Background gradient overlay */}
    <div className="absolute inset-0 bg-gradient-to-b from-gray-900/80 via-transparent to-gray-900/80 z-10" />
    
    {/* Scrolling columns as background */}
    <div className="absolute inset-0 grid grid-cols-3 gap-1 px-1">
      <ScrollColumn direction="down" />
      <ScrollColumn direction="up" />
      <ScrollColumn direction="down" />
    </div>
    
    {/* Content */}
    <div className="relative z-20 flex items-center justify-center min-h-screen p-4" style={{ marginTop: '-10vh' }}>
      <div className="flex flex-col items-center">
        <ShieldLogo />
        <div className="backdrop-blur-xl bg-white/10 p-4 sm:p-8 rounded-2xl sm:rounded-3xl border border-white/20 shadow-2xl max-w-3xl mx-2">
          <h1 className="text-3xl sm:text-5xl font-bold mb-4 sm:mb-6 bg-clip-text text-transparent bg-gradient-to-r from-rose-500 to-purple-600">
            Vigilancia Inteligente para tu Ciudad
          </h1>
          <p className="text-gray-200 text-base sm:text-lg">
            Sistema integral de seguridad ciudadana que conecta a la comunidad con las autoridades en tiempo real
          </p>
        </div>
      </div>
    </div>

    {/* Decorative blurred circles */}
    <div className="absolute top-1/4 left-1/4 w-48 sm:w-96 h-48 sm:h-96 bg-rose-500/20 rounded-full blur-3xl" />
    <div className="absolute bottom-1/4 right-1/4 w-48 sm:w-96 h-48 sm:h-96 bg-purple-500/20 rounded-full blur-3xl" />

    <style jsx>{`
      @keyframes scrollUp {
        0% { transform: translateY(0); }
        100% { transform: translateY(-50%); }
      }
      @keyframes scrollDown {
        0% { transform: translateY(-50%); }
        100% { transform: translateY(0); }
      }
      @keyframes drawShield {
        to {
          stroke-dashoffset: 0;
        }
      }
      @keyframes iconTransition {
        0% { opacity: 0; transform: scale(0.8); }
        20% { opacity: 1; transform: scale(1); }
        80% { opacity: 1; transform: scale(1); }
        100% { opacity: 0; transform: scale(0.8); }
      }

      .animate-iconTransition {
        animation: iconTransition 2s ease-in-out;
      }
    `}</style>
  </div>
);

export default Hero;