import React from 'react';
import { Shield, MapPin, Radio, FileText, Users, Clock, Bell, Star } from 'lucide-react';
import PoliceImageCard from './PoliceImageCard';

const FeatureCard = ({ icon: Icon, title, description, subFeatures, highlight }) => {
  return (
    <div className={`bg-gray-900 rounded-xl border border-gray-800 h-full p-6 ${
      highlight ? 'relative overflow-hidden' : ''
    }`}>
      {highlight && (
        <div className="absolute -inset-1 bg-gradient-to-r from-blue-600 to-blue-900 opacity-20 blur-lg" />
      )}
      <div className="flex items-center mb-4">
        <div className="w-12 h-12 bg-blue-500/10 rounded-lg flex items-center justify-center flex-shrink-0">
          <Icon className="w-6 h-6 text-blue-500" />
        </div>
        <h3 className="text-lg font-semibold text-white ml-4">{title}</h3>
      </div>
      <p className="text-sm text-gray-400 mb-4">{description}</p>
      <ul className="space-y-3">
        {subFeatures.map((feature, index) => (
          <li key={index} className="flex items-start gap-2">
            <div className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-2 flex-shrink-0" />
            <span className="text-sm text-gray-300">{feature}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

const PoliceDashboard = () => {
  const features = [
    {
      icon: MapPin,
      title: "Monitoreo en Tiempo Real",
      description: "Sistema integral de seguimiento de unidades y personal en servicio",
      subFeatures: [
        "Ubicación GPS de patrullas y elementos",
        "Historial detallado de recorridos",
        "Asignación de unidades por turno",
      ],
      highlight: true
    },
    {
      icon: Radio,
      title: "Comunicación Táctica",
      description: "Sistema de comunicación directa entre elementos y central",
      subFeatures: [
        "Chat interno entre elementos",
        "Comunicación masiva de emergencias",
        "Registro de comunicaciones"
      ]
    },
    {
      icon: Bell,
      title: "Gestión de Emergencias",
      description: "Control y seguimiento de incidentes reportados",
      subFeatures: [
        "Tiempo de respuesta por incidente",
        "Estadísticas de atención",
        "Alertas de emergencia prioritarias"
      ]
    },
    {
      icon: FileText,
      title: "Reportes y Estadísticas",
      description: "Sistema completo de análisis y documentación",
      subFeatures: [
        "Reportes por elemento y unidad",
        "Mapas de calor de incidencias",
        "Métricas de desempeño"
      ]
    }
  ];

  return (
    <div className="relative bg-gray-950 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        {/* Header */}
        <div className="text-center mb-12">
          <h1 className="text-3xl lg:text-4xl font-bold text-white mb-3">
            Centro de Control Policial
          </h1>
          <p className="text-lg text-gray-400 max-w-2xl mx-auto">
            Sistema Integral de Monitoreo y Gestión de Seguridad
          </p>
        </div>

        {/* Main Content */}
        <div className="grid lg:grid-cols-3 items-start">
          {/* Left Column - Main Image */}
          <div className="lg:col-span-1">
            <div className="relative">
              <div className="relative z-20">
                <img 
                  src="/dashboarCentral.png" 
                  alt="Dashboard Policial"
                  className="w-full max-w-sm mx-auto  shadow-2xl"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-gray-900/20 to-transparent rounded-2xl" />
              </div>
              <div className="absolute -inset-4 bg-blue-500/10 rounded-3xl blur-2xl -z-10" />
            </div>
          </div>

          {/* Right Columns - Feature Grid */}
          <div className="lg:col-span-2 grid sm:grid-cols-2 gap-6 -mt-10 z-30">
            {features.map((feature, index) => (
              <FeatureCard 
                key={index}
                {...feature}
              />
            ))}
          </div>
        </div>

        <PoliceImageCard />
        
        {/* Additional Features Bar */}
        <div className="mt-12 grid grid-cols-2 md:grid-cols-4 gap-4 bg-gray-900/50 p-6 rounded-xl">
          <div className="flex items-center gap-3">
            <Clock className="w-8 h-8 text-blue-500" />
            <div>
              <p className="text-white font-semibold">24/7</p>
              <p className="text-xs text-gray-400">Monitoreo Continuo</p>
            </div>
          </div>
          <div className="flex items-center gap-3">
            <Shield className="w-8 h-8 text-blue-500" />
            <div>
              <p className="text-white font-semibold">100%</p>
              <p className="text-xs text-gray-400">Cobertura Digital</p>
            </div>
          </div>
          <div className="flex items-center gap-3">
            <Star className="w-8 h-8 text-blue-500" />
            <div>
              <p className="text-white font-semibold">Evaluación</p>
              <p className="text-xs text-gray-400">Desempeño Policial</p>
            </div>
          </div>
          <div className="flex items-center gap-3">
            <Users className="w-8 h-8 text-blue-500" />
            <div>
              <p className="text-white font-semibold">Integración</p>
              <p className="text-xs text-gray-400">Multi-agencia</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoliceDashboard;