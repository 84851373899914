import React from 'react';
import { Shield, MapPin, Radio, FileText, Users, Clock, Bell, Star, Activity, Eye } from 'lucide-react';

const FeatureCard = ({ icon: Icon, title, description, subFeatures, highlight }) => {
  return (
    <div className={`bg-gray-900 rounded-xl border border-gray-800 h-full p-6 ${
      highlight ? 'relative overflow-hidden' : ''
    }`}>
      {highlight && (
        <div className="absolute -inset-1 bg-gradient-to-r from-rose-600 to-rose-900 opacity-20 blur-lg" />
      )}
      <div className="flex items-center mb-4">
        <div className="w-12 h-12 bg-rose-500/10 rounded-lg flex items-center justify-center flex-shrink-0">
          <Icon className="w-6 h-6 text-rose-500" />
        </div>
        <h3 className="text-lg font-semibold text-white ml-4">{title}</h3>
      </div>
      <p className="text-sm text-gray-400 mb-4">{description}</p>
      <ul className="space-y-3">
        {subFeatures.map((feature, index) => (
          <li key={index} className="flex items-start gap-2">
            <div className="w-1.5 h-1.5 rounded-full bg-rose-500 mt-2 flex-shrink-0" />
            <span className="text-sm text-gray-300">{feature}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

const StatsCard = ({ icon: Icon, title, value }) => (
  <div className="flex items-center gap-3 bg-gray-900/50 p-4 rounded-xl">
    <Icon className="w-8 h-8 text-rose-500" />
    <div>
      <p className="text-white font-semibold">{value}</p>
      <p className="text-xs text-gray-400">{title}</p>
    </div>
  </div>
);

const ImprovedDashboard = () => {
  const features = [
    {
      icon: MapPin,
      title: "Monitoreo en Tiempo Real",
      description: "Sistema integral de seguimiento y respuesta inmediata",
      subFeatures: [
        "Ubicación GPS en tiempo real",
        "Mapas de calor de incidencias",
        "Análisis predictivo de zonas",
      ],
      highlight: true
    },
    {
      icon: Radio,
      title: "Comunicación Táctica",
      description: "Red segura de comunicación multi-agencia",
      subFeatures: [
        "Chat encriptado entre elementos",
        "Alertas de emergencia prioritarias",
        "Coordinación interinstitucional"
      ]
    },
    {
      icon: Bell,
      title: "Gestión de Emergencias",
      description: "Sistema avanzado de respuesta a incidentes",
      subFeatures: [
        "Asignación inteligente de recursos",
        "Tiempo de respuesta optimizado",
        "Protocolos automatizados"
      ]
    },
    {
      icon: FileText,
      title: "Análisis y Reportes",
      description: "Plataforma integral de análisis de datos",
      subFeatures: [
        "Estadísticas en tiempo real",
        "Reportes personalizados",
        "Indicadores de desempeño"
      ]
    }
  ];

  const stats = [
    { icon: Clock, title: "Monitoreo Continuo", value: "24/7" },
    { icon: Shield, title: "Cobertura Digital", value: "100%" },
    { icon: Star, title: "Efectividad", value: "95%" },
    { icon: Users, title: "Integración", value: "Multi-agencia" }
  ];

  return (
    <div className="relative bg-gray-950 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        {/* Header */}
        <div className="text-center mb-12">
          <h1 className="text-3xl lg:text-4xl font-bold text-white mb-3">
            Centro de Control Integral
          </h1>
          <p className="text-lg text-gray-400 max-w-2xl mx-auto">
            Sistema Avanzado de Monitoreo y Gestión de Seguridad
          </p>
        </div>

        {/* Main Content */}
        <div className="grid lg:grid-cols-3 gap-8 items-start">
          {/* Left Column - Main Image */}
          <div className="lg:col-span-1">
            <div className="relative">
              <div className="relative z-20">
                <img 
                  src="/dashboardCentral.png" 
                  alt="Dashboard Central"
                  className="w-full max-w-sm mx-auto rounded-2xl shadow-2xl"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-gray-900/20 to-transparent rounded-2xl" />
              </div>
              <div className="absolute -inset-4 bg-rose-500/10 rounded-3xl blur-2xl -z-10" />
            </div>
          </div>

          {/* Right Columns - Feature Grid */}
          <div className="lg:col-span-2 grid sm:grid-cols-2 gap-6 z-30">
            {features.map((feature, index) => (
              <FeatureCard 
                key={index}
                {...feature}
              />
            ))}
          </div>
        </div>

        {/* Stats Grid */}
        <div className="mt-12 grid grid-cols-2 md:grid-cols-4 gap-4">
          {stats.map((stat, index) => (
            <StatsCard 
              key={index}
              {...stat}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImprovedDashboard;